<template>
  <section>
    <!--  -->
  </section>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getAuth"]),
  },
  async mounted() {
    const params = this.$route.params;
    // if (this.$route && this.$route.fullPath) {
    //   const data = this.$route.fullPath.substring(6); // /auth?#
    // console.log(params, this.getAuth);
    if (params.udid && this.getAuth) {
      const data = {
        id: -1,
        data: { udid: params.udid },
      };
      this.$store.dispatch("groups/updateGroup", data);
      //   const data = "access_token=" + params.code;
      //   try {
      //     await this.$store.dispatch("authCustom/" + provider + "Login", data);
      //     this.$router.push("/profile");
      //   } catch (err) {
      //     // console.log(err)
      //     this.$router.push("/auth/login");
      //   }
    } else {
      if (params.udid) {
        this.$store.dispatch("authCustom/setGroupRequest", params.udid);
      }
    }

    this.$router.push("/");
  },
};
</script>
